import { yupResolver } from "@hookform/resolvers/yup";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { Icons, theme } from "../../assets";
import {
    Timer,
    TimelineTrack,
    Tooltip,
    LoadingGray,
    RefuseReportToggle,
    PopoverHourTrack,
    SelectActivity,
    ModalHowYouKnowUs,
    ModalWelcomePlan,
} from "../../components";
import { HideInformation } from "../../components/atoms/hide-information/hide-information";
import { EditRunningTimerCard } from "../../components/molecules/card-edit-running-timer/card-edit-running-timer";
import { ModalDefineDailyGoal } from "../../components/molecules/modals/modal-define-daily-goal/modal-define-daily-goal";
import { ModalFavorites } from "../../components/molecules/modals/modal-favorites/modal-favorites";
import { HourLimitReachedModal } from "../../components/molecules/modals/modal-hour-limit-reached/modal-hour-limit-reached";
import ModalPlanCards from "../../components/molecules/modals/modal-plan-cards/modal-plan-cards";
import { TProjectList } from "../../components/molecules/popovers/popover-project/popover-project";
import { TutorialsPopover } from "../../components/molecules/tutorials-popover/tutorials-popover";
import { useUser } from "../../context";
import {
    TOption,
    TTrackCreateBody,
    TTrackCreateResponse,
    TTrackError,
} from "../../models";
import {
    TEntries,
    TLimits,
    TEntryTask,
    TLastTask,
    TDetailsEntry,
} from "../../models/entries";
import {
    activities,
    hourControl,
    manageUser,
    subscriptions,
    timeReports,
    track,
} from "../../services";
import {
    CreateActivityError,
    CreateActivityResponse,
    CreateActivityVariables,
    ReadActivitiesResponse,
    ReadActivitiesVariables,
} from "../../services/activities/types";
import { findErrorString } from "../../services/find-error-string";
import {
    ReadSubscriptionError,
    ReadSubscriptionResponse,
} from "../../services/subscriptions/types";
import {
    DeleteEntriesVariables,
    FinishEntriesError,
    FinishEntryResponse,
    FinishEntryVariables,
    StartEntryResponse,
    DeleteEntriesResponse,
    DeleteEntriesError,
    StartEntryError,
    StartEntryVariables,
    UpdateEntryResponse,
    UpdateEntryError,
    UpdateEntryVariables,
    UpdateRunningEntryError,
    UpdateRunningEntryVariables,
    UpdateRunningEntryResponse,
} from "../../services/track/types";
import { GetUserError, GetUserResponse } from "../../services/user/types";
import {
    calculateTimeRangeDuration,
    getHourAndMinutesAndSeconds,
    getMonthAndYearFromDate,
} from "../../utils";
import { DEFAULT_TRIAL_DAYS } from "../../utils/constants/free-days";
import cookies from "../../utils/cookies-fn/cookies";
import { Mixpanel } from "../../utils/mixpanel/Mixpanel";
import { socket } from "../../utils/socket";
import { validatePermittedStrings } from "../../utils/validation";
import {
    TReadActivitiesSection,
    TSectionActivity,
} from "../organization/project/tab-activities/types";
import { formatCurrency } from "../organization/project/tab-costs/utils";
import { DiscoverOurPlans } from "./components/banners/discover-our-plans/discover-our-plans";
import { ErrorInPaymentBanner } from "./components/banners/error-in-payment/error-in-payment";
import { PlanCanceledBanner } from "./components/banners/plan-canceled/plan-canceled";
import { WelcomeBanner } from "./components/banners/welcome-banner/welcome-banner";
import { TrackButtons } from "./components/buttons/track-buttons";
import { TrackCalendar } from "./components/calendar/track-calendar";
import { DailyGoalTimeline } from "./components/daily-goal-timeline/daily-goal-timeline";
import { TrackEntries } from "./components/entries/track-entries";
import { Footer } from "./components/footer/footer";
import { HoursInMonth } from "./components/hours-in-month/hours-in-month";
import { TrackIcons } from "./components/icons/track-icons";
import { TrackInput } from "./components/input/track-input";
import { LimitReachedWarning } from "./components/limit-reached-warning/limit-reached-warning";
import { RequestMoreHours } from "./components/request-more-hours/request-more-hours";
import { SelectProject } from "./components/select-project/select-project";
import VoiceAssistant from "./components/voice-assistant/voice-assistant";
import {
    DEFAULT_START_TIME,
    MIN_VALUE_TO_REGISTER_IN_SECONDS,
    ONE_DAY_IN_HOURS,
    ONE_MINUTE_IN_SECONDS,
    READ_ENTRIES_LENGTH,
} from "./constants";
import * as s from "./styled-track";
import { TrackService } from "./track-service";
import { schema, manualSchema } from "./validation-track";

dayjs.extend(timezone);
dayjs.extend(utc);

export type TSectionsActivitiesOption = {
    label?: string;
    options?: TOption[];
    value?: string;
    section?: string;
    lastTask?: boolean;
};

export const Track = () => {
    const [readEntriesLength, setReadEntriesLength] =
        useState(READ_ENTRIES_LENGTH);

    const {
        userProjects,
        workedHours,
        user,
        refreshUser,
        setOrganization,
        refreshProject,
        organization,
        favoriteProject,
        favoriteActivity,
    } = useUser();
    const location = useLocation();
    const page = 1;

    const [showMoneyInfo, setShowMoneyInfo] = useState(false);
    const [isRunning, setIsRunning] = useState<boolean>();
    const [startTime, setStartTime] = useState<number>();
    const [loading, setLoading] = useState(true);
    const [timeEntries, setTimeEntries] = useState<TEntries>();
    const [isOnManualMode, setIsOnManualMode] = useState<boolean>(false);
    const [allDuration, setAllDuration] = useState(0);
    const [selectedDay, setSelectedDay] = useState<Date>(new Date());
    const [project, setProject] = useState<TProjectList | undefined>();
    const [manualInputsData, setManualInputsData] = useState({
        start: getHourAndMinutesAndSeconds(
            new Date(new Date().valueOf() - 1000 * ONE_MINUTE_IN_SECONDS)
        ),
        end: getHourAndMinutesAndSeconds(new Date()),
        startUpdated: false,
        endUpdated: false,
        startKey: Math.random().toString(),
        endKey: Math.random().toString(),
    });
    const loadingTimer = useRef(false);
    const [limits, setLimits] = useState<TLimits>({
        greaterThan80: false,
        greaterThan90: false,
        max: false,
    });
    const [isTimeLimitReachedModalOpen, setTimeLimitReachedModalOpen] =
        useState(false);
    const [timeLimitReachedModalData, setTimeLimitReachedModalData] = useState({
        limitLeft: 0,
        entryDuration: 0,
        hourLimit: false,
    });
    const [editRunningTimerIsOpen, setEditRunningTimerIsOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] =
        useState<TSectionsActivitiesOption>();
    const [lastTasks, setLastTasks] = useState<TLastTask[]>([]);
    const [howYouKnowUsModal, setHowYouKnowUsModal] = useState(
        Boolean(location.state?.fromRegister)
    );
    const [welcomeBannerIsOpen, setWelcomeBannerIsOpen] = useState(false);
    const [planExpiresDate, setPlanExpiresDate] = useState<string>("0");
    const [numberOfUsersPlan, setNumberOfUsersPlan] = useState<number>(4);
    const [discoverOurPlansUnique, setDiscoverOurPlansUnique] = useState(false);
    const [isTrialPlanOpen, setIsTrialPlanOpen] = useState(false);
    const [isOpenModalPlanCards, setIsOpenModalPlanCards] = useState(false);
    const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(false);
    const [clearDescription, setClearDescription] = useState(
        user.UsersConfigs.clearTaskFieldsAfterAdd
    );
    const [runningEntry, setRunningEntry] = useState<
        TDetailsEntry | undefined
    >();
    const [favoriteModalOpen, setFavoriteModalOpen] = useState(false);
    const [showDailyGoal, setShowDailyGoal] = useState<boolean>(false);
    const [showDefineDailyGoalModal, setShowDefineDailyGoalModal] =
        useState<boolean>(false);
    const [voiceAssistantModal, setVoiceAssistantModal] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors },
        resetField,
    } = useForm({
        resolver: yupResolver(isOnManualMode ? manualSchema : schema),
    });

    const readSubscription = useQuery<
        unknown,
        ReadSubscriptionError,
        ReadSubscriptionResponse
    >({
        queryKey: ["readSubscription"],
        queryFn: subscriptions.ReadSubscription,
        cacheTime: 0,
        retryDelay: 0,
    });

    const readEntries = useQuery<TEntries>({
        queryKey: ["readEntries", page, readEntriesLength],
        queryFn: () =>
            track.getEntries({
                page: 1,
                responseFormat: "timeTrack",
                length: readEntriesLength,
            }),
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    const readActivities = useQuery<
        ReadActivitiesVariables,
        { message: string },
        ReadActivitiesResponse
    >({
        queryKey: ["readActivities"],
        queryFn: () => activities.readActivities({ projectId: project?.id_e }),
        enabled: false,
    });

    const getUser = useQuery<unknown, GetUserError, GetUserResponse>({
        queryKey: ["getUser"],
        queryFn: () => manageUser.getUser({ reportErrors: true }),
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const userId = getUser.data?.id_e;

    const getHourControl = useQuery({
        queryKey: ["getHourControl"],
        queryFn: () => hourControl.getHourControl({ userId }),
        enabled: Boolean(userId),
        refetchOnWindowFocus: false,
    });

    const refetchEntries = () => {
        readEntries.refetch({
            queryKey: ["readEntries", { page, length: readEntriesLength }],
        });
        refreshUser();
        getHourControl.refetch();
    };

    const startLocalEntry = () => {
        setStartTime(DEFAULT_START_TIME);
        setIsRunning(true);
    };

    const sortTasks = (tasks: TEntryTask[]) => {
        return tasks.sort((task1, task2) => {
            const latestTask1EndAt = Math.max(
                ...task1.entries.map((entry) =>
                    new Date(entry.end_at).getTime()
                )
            );
            const latestTask2EndAt = Math.max(
                ...task2.entries.map((entry) =>
                    new Date(entry.end_at).getTime()
                )
            );
            return latestTask2EndAt - latestTask1EndAt;
        });
    };

    const createLocalEntry = (
        createdEntry:
            | TTrackCreateResponse["created_entries"][0]
            | FinishEntryResponse["created_entries"][0]
    ) => {
        const entryDate = dayjs(createdEntry.start_date).add(3, "hours");
        const formattedDate = entryDate.format("YYYY-MM-DD");

        const createdTask = {
            taskName: createdEntry.description,
            project: createdEntry.Project,
            entryChecked: false,
            entryOpen: false,
            sector: {
                id_e: createdEntry.sector_id,
                name: createdEntry.sector_id,
            },
            entries: [
                {
                    ...createdEntry,
                    user_id: user.id_e,
                    entryChecked: false,
                },
            ],
        };

        if (!timeEntries?.entries.length) {
            setTimeEntries({
                ...timeEntries,
                entries: [
                    {
                        date: formattedDate,
                        dayChecked: false,
                        dayOpen: true,
                        tasks: [createdTask],
                    },
                ],
                total_entries: 1,
            } as TEntries);
            return;
        }

        setTimeEntries((prev) => {
            const entries = prev?.entries || [];
            const entriesHavePickedDate = entries.some(({ date }) =>
                dayjs(date).isSame(entryDate, "day")
            );
            loadingTimer.current = false;
            if (entriesHavePickedDate === false) {
                entries.push({
                    date: formattedDate,
                    dayChecked: false,
                    dayOpen: true,
                    tasks: [createdTask],
                });
                const orderedEntriesByDate = entries.sort((a, b) => {
                    return (
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                    );
                });
                return {
                    ...prev,
                    entries: orderedEntriesByDate,
                    total_entries: (prev?.total_entries || 0) + 1,
                } as TEntries;
            }
            const newEntries = entries.map((entry) => {
                if (entry.date !== formattedDate) return entry;
                const tasks: TEntryTask[] = deepClone(entry.tasks);
                if (!tasks.length) {
                    return {
                        ...entry,
                        tasks: [createdTask],
                    };
                }
                const haveSameNameTasks = tasks.some((entryTask) => {
                    return (
                        entryTask.taskName === createdTask.taskName &&
                        entryTask.project.id_e === createdTask.project.id_e
                    );
                });
                if (
                    !haveSameNameTasks ||
                    user.UsersConfigs.groupTasks === false
                ) {
                    const newTasks = [createdTask, ...entry.tasks];
                    const sortedTasks = sortTasks(newTasks);
                    return {
                        ...entry,
                        tasks: sortedTasks,
                    };
                }
                const newTasks = tasks.map((entryTask) => {
                    const haveSameName = entryTask.entries.every(
                        (detailsEntry) =>
                            detailsEntry.description === createdTask.taskName
                    );
                    const entryAlreadyCreated = entryTask.entries.some(
                        (detailsEntry) => {
                            return (
                                detailsEntry.start_at ===
                                    createdTask.entries[0].start_at ||
                                detailsEntry.end_at ===
                                    createdTask.entries[0].end_at
                            );
                        }
                    );
                    if (!haveSameName || entryAlreadyCreated) {
                        return entryTask;
                    }
                    const newEntries = [
                        ...entryTask.entries,
                        createdTask.entries[0],
                    ];
                    const orderedEntries = newEntries.sort((a, b) => {
                        return (
                            new Date(b.end_at).getTime() -
                            new Date(a.end_at).getTime()
                        );
                    });
                    return { ...entryTask, entries: orderedEntries };
                });
                const sortedTasks = sortTasks(newTasks);
                return {
                    ...entry,
                    tasks: sortedTasks,
                };
            });
            return { ...prev, entries: newEntries } as TEntries;
        });
    };

    const clearDescriptionAndProject = (force?: boolean) => {
        if (!clearDescription && !force) return;
        setValue("description", "");
        setSelectedActivity(undefined);
        setProject(undefined);
    };

    const startEntry = useMutation<
        StartEntryResponse,
        StartEntryError,
        StartEntryVariables
    >({
        mutationFn: track.startEntry,
        onSuccess: (data) => {
            setRunningEntry(data as unknown as TDetailsEntry);
            startLocalEntry();
        },
        onError: (error) => {
            const errorsToStopRunning = [
                "Activity is archived",
                "impossible to do this to a locked report!",
            ];
            const errorString = findErrorString(error.body);
            if (errorString === "project is archived") {
                readEntries.refetch();
                refreshProject({
                    sectors: true,
                    showOnlyActiveProjects: true,
                });
                setIsRunning(false);
                setStartTime(DEFAULT_START_TIME);
                setRunningEntry(undefined);
                clearDescriptionAndProject(true);
            }
            if (errorsToStopRunning.includes(errorString)) {
                setIsRunning(false);
                setStartTime(DEFAULT_START_TIME);
            }
            toast.error(error.message);
        },
    });

    const finishEntry = useMutation<
        FinishEntryResponse,
        FinishEntriesError,
        FinishEntryVariables
    >({
        mutationFn: track.finishEntry,
        onSuccess: (data) => {
            setIsRunning(false);
            setStartTime(DEFAULT_START_TIME);
            loadingTimer.current = false;
            createLocalEntry(data.created_entries[0]);
            toast.success("Registro feito com sucesso!");
            clearDescriptionAndProject();
        },
        onError: (error) => {
            toast.error(error.message);
            refetchEntries();
            const errorBody = error.body?.message;
            const userHourLimitReachedMessage =
                "It's impossible to do this, you've exceeded the hour limit";
            const projectHourLimitReachedMessage =
                "It's impossible to do this, you've exceeded the project hour limit";
            const timeReachedErrors = [
                userHourLimitReachedMessage,
                projectHourLimitReachedMessage,
            ];
            const isTimeReachedError = timeReachedErrors.includes(
                errorBody?.info
            );
            if (!isTimeReachedError) return;
            const isProjectHourLimitReached =
                errorBody?.info === userHourLimitReachedMessage;
            setTimeLimitReachedModalData({
                limitLeft: errorBody.minutesToAdept,
                entryDuration: errorBody.extrapolatedMinutes,
                hourLimit: isProjectHourLimitReached,
            });
            setTimeLimitReachedModalOpen(true);
            const { lastEntry } = errorBody;
            const startAt = dayjs(lastEntry?.start_at);
            const correctedTime = startAt
                .add(errorBody.minutesToAdept, "minute")
                .add(3, "hour")
                .format();
            finishEntry.mutate({
                description: watch("description"),
                endAt: correctedTime,
                project: lastEntry?.project_id,
                sectorId: lastEntry?.sector_id,
                activityId: selectedActivity?.value,
            });
        },
    });

    const deleteLocalEntries = (ids: string[]) => {
        setTimeEntries((prev) => {
            if (!ids.length || !prev) return prev;
            const { entries: entriesDay } = prev;

            for (
                let entryDayIndex = 0;
                entryDayIndex < entriesDay.length;
                entryDayIndex += 1
            ) {
                const entryDay = entriesDay[entryDayIndex];
                let { tasks } = entryDay;

                tasks.forEach((task, taskIndex) => {
                    const filteredEntries = task.entries.filter(
                        (entry) => !ids.includes(entry.id_e)
                    );
                    tasks[taskIndex].entries = filteredEntries;
                });
                tasks = tasks.filter((task) => task.entries.length > 0);
                if (tasks.length === 0) {
                    entriesDay.splice(entryDayIndex, 1);
                    entryDayIndex -= 1;
                } else {
                    entryDay.tasks = tasks;
                }
            }
            return prev;
        });
    };

    const deleteEntry = useMutation<
        DeleteEntriesResponse,
        DeleteEntriesError,
        DeleteEntriesVariables
    >({
        mutationFn: track.deleteEntry,
        onSuccess: (data) => {
            const entriesIds = data.message.split("! ")[1].split(", ");
            deleteLocalEntries(entriesIds);
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });

    const openDayWhenCreateEntry = (
        createdEntry: TTrackCreateResponse["created_entries"][0]
    ) => {
        setTimeEntries({
            ...timeEntries,
            entries: timeEntries?.entries?.map((entry) => {
                const createdEntryDay = dayjs(createdEntry.start_date).add(
                    3,
                    "hour"
                );
                const createdEntryIsOnThisDay = createdEntryDay.isSame(
                    dayjs(entry.date),
                    "day"
                );
                if (createdEntryIsOnThisDay) {
                    entry.dayOpen = true;
                }
                return entry;
            }),
        } as TEntries);
    };

    const createEntry = useMutation<
        TTrackCreateResponse,
        TTrackError,
        TTrackCreateBody
    >({
        mutationFn: track.createEntry,
        onSuccess: (data) => {
            const createdEntry = data.created_entries[0];
            toast.success("Registro realizado com sucesso!");
            clearDescriptionAndProject();
            if (createdEntry) openDayWhenCreateEntry(createdEntry);
            refreshUser();
            createLocalEntry(createdEntry);
        },
        onError: (error) => {
            const errorString = findErrorString(error.body);
            if (errorString === "project is archived") {
                clearDescriptionAndProject(true);
                refreshProject({
                    sectors: true,
                    showOnlyActiveProjects: true,
                });
            }
            setTimeEntries((prev) => {
                const entries = prev?.entries.map((entryDay) => {
                    const newEntryDay = {
                        ...entryDay,
                        tasks: entryDay.tasks.map((task) => {
                            return {
                                ...task,
                                entries: task.entries.filter(
                                    (entry) => entry.id_e
                                ),
                            };
                        }),
                    };
                    return newEntryDay;
                });
                return {
                    ...prev,
                    entries,
                } as TEntries;
            });
            toast.error(error.message);
        },
    });

    const updateEntry = useMutation<
        UpdateEntryResponse,
        UpdateEntryError,
        UpdateEntryVariables
    >({
        mutationKey: ["updateEntry"],
        mutationFn: track.updateEntry,
        onSuccess: () => {
            toast.success("Registro atualizado com sucesso!");
        },
        onError: (error) => {
            toast.error(error.message);
            setTimeEntries({
                ...timeEntries,
                entries: timeEntries?.entries.map((day) => {
                    return {
                        ...day,
                        dayOpen: false,
                    };
                }),
            } as TEntries);
        },
        onSettled: () => {
            readEntries.refetch();
            refreshUser();
        },
    });

    const updateRunningEntry = useMutation<
        UpdateRunningEntryResponse,
        UpdateRunningEntryError,
        UpdateRunningEntryVariables
    >({
        mutationFn: track.updateRunningEntry,
        onSuccess: (data) => {
            toast.success("Registro atualizado com sucesso!");
            if (runningEntry) {
                setRunningEntry({
                    ...runningEntry,
                    start_at: data.start_at,
                });
            }
            setStartTime(new Date(data?.start_at.replace("Z", "")).getTime());
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });

    const lockReport = useMutation({
        mutationFn: timeReports.patchLockReport,
        onSuccess: () => getUser.refetch(),
    });

    const createActivity = useMutation<
        CreateActivityResponse,
        CreateActivityError,
        CreateActivityVariables
    >({
        mutationFn: activities.createActivity,
        onSuccess: (data) => {
            if (project?.id_e) {
                setSelectedActivity({
                    label: data.name,
                    value: data.id_e,
                });
                readActivities.refetch();
                toast.success("Atividade criada com sucesso!");
            }
        },
    });

    const updateUserConfigs = useMutation({
        mutationKey: ["updateUserConfigs"],
        mutationFn: manageUser.updateUserConfigs,
    });

    const findRunningEntry = (response: TEntries | undefined) => {
        const task = response?.entries[0]?.tasks[0];
        if (!task?.entries[0]) return null;
        return task?.entries[0]?.end_at ? null : task?.entries[0];
    };

    const hoursLocked = getUser?.data?.hoursLock;
    const hourLimitReached = Boolean(hoursLocked && limits.max);
    const hourControlData: never[] = getHourControl?.data?.[0]?.HourControl;
    const userRole = getUser?.data?.Role?.name;
    const isAdminUser = userRole === "Administrador";
    const maxLimitReached = hoursLocked && limits.max;
    const nineTenthLimitReached = hoursLocked && limits.greaterThan90;
    const hasNextPage = timeEntries && timeEntries.total_pages > page;
    const userConfigs = getUser.data?.UsersConfigs;

    const projectActivitiesOptions = (() => {
        let options: TSectionsActivitiesOption[] = [];
        if (readActivities.data) {
            const optionsWithSections =
                readActivities.data.noArchived.sections.map(
                    (section: TReadActivitiesSection) => {
                        const activitiesOptions = section.activities.map(
                            (activity: TSectionActivity) => ({
                                label: activity.name,
                                value: activity.id_e,
                            })
                        );
                        return {
                            label: section.sectionName,
                            options: activitiesOptions,
                        };
                    }
                );
            const optionsWithoutSections = [
                {
                    label: "Sem sessão",
                    options: readActivities.data.noArchived.activities.map(
                        (activity: TSectionActivity) => ({
                            label: activity.name,
                            value: activity.id_e,
                        })
                    ),
                },
            ];
            options = [...optionsWithSections, ...optionsWithoutSections];
        }
        if (lastTasks?.length) {
            const projectLastTasks = lastTasks.filter(
                (task) =>
                    task?.project.id_e === project?.id_e && task.activities
            );
            const lastActivitiesOptions = {
                label: "Últimas tarefas",
                options: projectLastTasks.map((task) => ({
                    label: task?.activities?.name,
                    value: task?.activities?.id_e,
                    section: task?.activities?.ActivitySection?.name,
                    lastTask: true,
                })),
            };
            options = [lastActivitiesOptions, ...options];
        }
        return options;
    })();

    const handleDeleteEntry = (id: string) => {
        deleteEntry.mutate({ id });
    };

    const manageWorkedHours = useCallback(
        (time: number) => {
            TrackService.updateLimits({
                limits,
                setLimits,
                time,
                workedHours,
                isRunning,
            });
        },
        [workedHours.limit, isRunning]
    );

    const validateInputs = (start: string, end: string, message?: boolean) => {
        const { isValid, errorMessage } = TrackService.validateTimes(
            start,
            end,
            selectedDay
        );
        const hasError = !isValid && errorMessage && message;
        if (hasError) toast.error(errorMessage);

        return isValid;
    };

    const handleDeleteCurrentTime = () => {
        const currentTime =
            startEntry?.data?.id_e ||
            readEntries?.data?.entries[0]?.tasks[0]?.entries[0].id_e;
        if (currentTime) {
            handleDeleteEntry(currentTime);
        }
        setIsRunning(false);
        setStartTime(DEFAULT_START_TIME);
        resetField("startTrackTime");
    };

    const renderInputFields = () => {
        if (isOnManualMode) {
            return (
                <>
                    <TrackInput
                        disabled={hourLimitReached}
                        register={register}
                        type="start"
                        manualInputsData={manualInputsData}
                        setManualInputsData={setManualInputsData}
                        validateInputs={validateInputs}
                    />

                    <Icons.Arrow
                        title={
                            "Seta indicando que o tempo inicial é o da esquerda e o tempo final é o da direita"
                        }
                    />

                    <TrackInput
                        register={register}
                        type="end"
                        manualInputsData={manualInputsData}
                        setManualInputsData={setManualInputsData}
                        validateInputs={validateInputs}
                    />

                    <TrackCalendar
                        disabled={hourLimitReached}
                        control={control}
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        sideOffset={20}
                    />
                </>
            );
        }

        return (
            <Controller
                key={isRunning ? "running" : "not-running"}
                control={control}
                name="duration"
                render={({ field: { onChange } }) => (
                    <s.BigP>
                        <Timer
                            key={`${startTime}`}
                            onClick={() =>
                                setEditRunningTimerIsOpen(
                                    !editRunningTimerIsOpen
                                )
                            }
                            startTime={startTime}
                            isRunning={isRunning}
                            onChange={(currentTime) => {
                                if (isRunning)
                                    document.title = `${
                                        readEntries?.data?.entries[0]?.tasks[0]
                                            ?.taskName || ""
                                    }`;

                                onChange(currentTime);
                                if (isRunning) {
                                    manageWorkedHours(
                                        currentTime +
                                            Number(
                                                allDuration /
                                                    ONE_MINUTE_IN_SECONDS
                                            )
                                    );
                                }
                            }}
                        />
                    </s.BigP>
                )}
            />
        );
    };

    const sendCorrections = () => {
        lockReport.mutate({
            reportId:
                getUser?.data?.TimeReports?.[0]?.ReportError[0]?.report_id,
        });
    };

    const onSubmit = handleSubmit(({ description, duration, calendar }) => {
        const isValidDescription = validatePermittedStrings(
            selectedActivity?.label as string
        );
        if (!isValidDescription) {
            return;
        }
        const minDurationReached = duration >= MIN_VALUE_TO_REGISTER_IN_SECONDS;
        if (isRunning && !minDurationReached) {
            toast.error(
                "Registros com menos de 30 segundos não são adicionados!",
                { id: "small-entry" }
            );
            return;
        }
        Mixpanel.track("Criar registro de tempo", {
            type: isOnManualMode ? "manual" : "time track",
        });
        if (isRunning) {
            loadingTimer.current = true;
            finishEntry.mutate({
                description,
                endAt: new Date().toISOString(),
                project: project?.id_e,
                sectorId: project?.Sectors?.[0]?.id_e,
                activityId: selectedActivity?.value,
            });
            setIsRunning(false);
            setStartTime(DEFAULT_START_TIME);
            return;
        }
        if (isOnManualMode) {
            const formattedData = calculateTimeRangeDuration(
                calendar || new Date(),
                manualInputsData.start,
                manualInputsData.end
            );
            const limitLeft = workedHours.limit - workedHours.total;
            const durationInMinutes = Math.round(
                formattedData.duration / ONE_MINUTE_IN_SECONDS
            );
            const timeLeft = limitLeft - durationInMinutes;
            if (timeLeft < 0 && hoursLocked) {
                toast.error(
                    "Esse registro ultrapassa o limite de horas definidas pelo seu gestor."
                );
                return;
            }
            validateInputs(manualInputsData.start, manualInputsData.end);
            createEntry.mutate({
                startAt: formattedData.startTrackTime,
                endAt: formattedData.finalTrackTime,
                project: project?.id_e,
                sectorId: project?.Sectors[0].id_e,
                activityId: selectedActivity?.value,
            });
            return;
        }
        setValue("description", selectedActivity?.label);
        startEntry.mutate({
            startAt: new Date(),
            project: project && project.id_e,
            sectorId: project?.Sectors[0].id_e,
            activityId: selectedActivity?.value,
        });
    });

    const calculateWorkedHoursToday = () => {
        const lastDay = timeEntries?.entries[0];
        if (!lastDay) return 0;
        const haveEntryToday = dayjs(lastDay.date).isSame(dayjs(), "day");
        if (!haveEntryToday) return 0;
        let total = 0;
        lastDay.tasks.forEach((task) => {
            task.entries.forEach((entry) => {
                total += entry.total_time;
            });
        });
        return total;
    };

    const startTimer = (
        readEntriesData: TEntries,
        runningEntry: TDetailsEntry
    ) => {
        const firstTask = readEntriesData.entries[0]?.tasks[0];
        const entryProject = userProjects.find(
            ({ id_e }) => id_e === firstTask?.entries[0]?.Project?.id_e
        );
        if (entryProject) {
            setProject(entryProject);
        }
        setIsRunning(true);
        setStartTime(
            new Date(firstTask.entries[0]?.start_at.replace("Z", "")).getTime()
        );
        setValue("description", firstTask.taskName);
        setValue("startTrackTime", firstTask.entries[0]?.start_at);
        firstTask.entries.splice(0, 1);
        setTimeEntries(readEntriesData);
        setSelectedActivity({
            label: runningEntry?.Activity?.name,
            value: runningEntry?.Activity?.id_e,
        });
    };

    /*     const handleGameficationSocket = (data: GameficationSocketData) => {
        if (!data) return;
        const isChallenge = data.finished_type === "challenge";
        if (!isChallenge) return;
        const { description } = data.challenge_completed;
        const isAddTime = description === "Adicionar tempo no cronometro";
        if (isChallenge && isAddTime) {
            const audio = new Audio(
                // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                require("../../assets/sounds/challenge-completed.mp3")
            );
            audio.play();

            toast.success(
                `Você inseriu um tempo no cronômetro e ganhou ${data.challenge_completed.points} pts!`,
                challengeDoneToastOptions
            );
        }
        refetchDailyMission();
    };

    useEffect(() => {
        const key = `gamefication-${getUser?.data?.id_e}`;
        if (getUser?.data?.id_e) {
            socket.on(key, handleGameficationSocket);
        }
        return () => {
            socket.off(key);
        };
    }, [getUser?.data]); */

    useEffect(() => {
        if (favoriteProject && !isRunning) {
            setProject(favoriteProject);
        }
    }, [favoriteProject]);

    useEffect(() => {
        if (
            favoriteActivity &&
            favoriteActivity.name !== null &&
            favoriteActivity.id_e !== null &&
            !isRunning
        ) {
            setSelectedActivity({
                label: favoriteActivity.name,
                value: favoriteActivity.id_e,
            });
        }
    }, [favoriteActivity]);

    useEffect(() => {
        if (sessionStorage.getItem("planChoice")) setIsOpenWelcomeModal(true);
        if (cookies.get().project && cookies.get().project !== "undefined")
            setProject(JSON.parse(cookies.get().project));

        return () => {
            document.title = "Paper On";
        };
    }, []);

    useEffect(() => {
        socket.on(`${getUser?.data?.id_e} - reportError`, (data) => {
            if (data?.newReportError?.report_id) {
                new Audio(
                    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
                    require("../../assets/sounds/notification.mp3")
                ).play();
                getUser.refetch();
            }
        });
        return () => {
            socket.off(`${getUser?.data?.id_e} - reportError`);
        };
    }, [getUser?.data]);

    useEffect(() => {
        if (!user.email) {
            return;
        }
        const newAccounts: string[] = JSON.parse(
            localStorage.getItem("newAccounts") || "[]"
        );
        if (location.state?.fromRegister) {
            localStorage.setItem(
                "newAccounts",
                JSON.stringify([...newAccounts, user.email])
            );
        }
        if (newAccounts.includes(user.email)) {
            setWelcomeBannerIsOpen(true);
        }
    }, [user.email]);

    useEffect(() => {
        if (!readEntries.data) {
            return;
        }
        const response: TEntries = structuredClone(readEntries.data);
        const openedEntries = timeEntries?.entries
            ?.filter(({ dayOpen }) => dayOpen)
            ?.map(({ date }) => date);
        const alreadyRunningEntry = findRunningEntry(response);
        if (alreadyRunningEntry) {
            setRunningEntry(alreadyRunningEntry);
            startTimer(response, alreadyRunningEntry);
        } else {
            loadingTimer.current = false;
            setStartTime(DEFAULT_START_TIME);
        }
        const incomingEntries = response.entries.map((entry) => ({
            ...entry,
            dayChecked: false,
            dayOpen: Boolean(
                openedEntries?.includes(entry.date) ||
                    dayjs(entry.date).isSame(dayjs(), "day")
            ),
            tasks: entry.tasks
                .map((task) => ({
                    ...task,
                    entryChecked: false,
                    entryOpen: false,
                    entries: task.entries.map((entry) => ({
                        ...entry,
                        entryChecked: false,
                    })),
                }))
                .filter((item) => {
                    const itemHasEnd = item.entries.some(
                        (entry) => entry.end_at
                    );
                    return itemHasEnd;
                })
                .filter((item) => item.entries.length > 0),
        }));
        setTimeEntries({
            ...readEntries.data,
            entries: incomingEntries,
        });
        setLastTasks(readEntries.data.lastTasks);
        setLoading(false);
    }, [readEntries.data, userProjects]);

    useEffect(() => {
        if (!timeEntries) return;
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        const [actualDate] = Object.keys(timeEntries?.time_requested).filter(
            (item) => item === `0${month + 1}-${year}`
        );
        setAllDuration(timeEntries.time_requested[actualDate]);
    }, [JSON.stringify(timeEntries)]);

    useEffect(() => {
        if (workedHours.limit && workedHours.total) {
            manageWorkedHours(workedHours.total);
            const collaboratorWorkedTheLimit =
                workedHours.limit - workedHours.total;
            setLimits((prevState) => ({
                ...prevState,
                max: collaboratorWorkedTheLimit <= 0,
            }));
        }
    }, [workedHours]);

    useEffect(() => {
        if (project) {
            readActivities.refetch();
            setValue("project", project?.name);
        }
    }, [project]);

    useEffect(() => {
        if (hourLimitReached && isRunning) {
            setIsRunning(false);
            setProject(undefined);
            onSubmit();
            reset();
        }
    }, [limits]);

    useEffect(() => {
        const savedShowDailyGoal =
            localStorage.getItem("showDailyGoal") === "true";
        setShowDailyGoal(savedShowDailyGoal);
    }, []);

    const calculateTrialExpiration = (
        creationDate: string,
        trialDays = DEFAULT_TRIAL_DAYS
    ) => {
        const todayDate = dayjs();
        const planExpiresDate = dayjs(creationDate)
            .add(trialDays, "day")
            .endOf("day");
        const hoursToExpireTrialSubscription = planExpiresDate.diff(
            todayDate,
            "hours"
        );

        return {
            hours: hoursToExpireTrialSubscription,
            days: Math.min(
                Math.floor(hoursToExpireTrialSubscription / ONE_DAY_IN_HOURS),
                trialDays
            ),
        };
    };

    const handleTrialSubscription = () => {
        if (!readSubscription.data || !userRole) {
            return;
        }
        setNumberOfUsersPlan(readSubscription.data?.number_of_users);
        const isTrial = readSubscription.data?.plan === "trial" && isAdminUser;
        if (!isTrial) {
            return;
        }
        setIsTrialPlanOpen(true);
        const { hours, days } = calculateTrialExpiration(
            readSubscription.data?.created_at,
            readSubscription.data?.trial_days
        );
        if (hours <= 0) {
            setPlanExpiresDate("Nenhum dia");
            setDiscoverOurPlansUnique(true);
            setIsOpenModalPlanCards(true);
            return;
        }
        if (hours <= ONE_DAY_IN_HOURS) {
            setPlanExpiresDate(`${hours} ${hours <= 1 ? "hora" : "horas"}`);
            if (hours <= 1) setDiscoverOurPlansUnique(true);
            return;
        }
        if (days <= 0) {
            refreshUser();
            setPlanExpiresDate("Nenhum dia");
            setDiscoverOurPlansUnique(true);
            setIsOpenModalPlanCards(true);
            return;
        }
        if (days === 1) {
            setPlanExpiresDate(`${days} dia`);
            setDiscoverOurPlansUnique(true);
            return;
        }
        setPlanExpiresDate(`${days} dias`);
    };

    useEffect(() => {
        handleTrialSubscription();
        if (readSubscription.data) {
            setOrganization({
                ...organization,
                Subscription: readSubscription.data,
            });
        }
    }, [readSubscription.data, getUser.data]);

    useEffect(() => {
        if (!readSubscription?.error?.body || !userRole) return;
        const error = readSubscription?.error?.body;
        if (error.message.info === "trial period ended" && isAdminUser) {
            refreshUser();
            setNumberOfUsersPlan(error?.message?.numberOfUsers);
            setIsOpenModalPlanCards(true);
            setIsTrialPlanOpen(true);
            setPlanExpiresDate("Nenhum dia");
            setDiscoverOurPlansUnique(true);
        } else if (
            (error.message as unknown as string) !== "subscription has expired"
        ) {
            setNumberOfUsersPlan(error.message?.subscription?.number_of_users);
            setIsOpenModalPlanCards(true);
            setPlanExpiresDate("Nenhum dia");
        }
    }, [readSubscription.error]);

    const continueTask = (task: TEntryTask) => {
        const isValid = validatePermittedStrings(task.taskName);
        if (!isValid) return;
        if (isRunning) {
            toast.error("Você já possui uma entrada de tempo aberta");
            return;
        }
        if (!task.sector) {
            toast.error("Não foi possível continuar a tarefa.");
            return;
        }
        if (isOnManualMode) {
            setIsOnManualMode(false);
        }
        const projectInformation = userProjects.find((project) => {
            return project.id_e === task.project.id_e;
        });
        setProject(projectInformation);
        setValue("description", task.entries?.[0]?.Activity?.name);
        setSelectedActivity({
            label: task.entries?.[0]?.Activity?.name,
            value: task.entries?.[0]?.Activity?.id_e,
        });
        const activityId =
            task.entries?.[0]?.Activity?.id_e || selectedActivity?.value;
        startEntry.mutate({
            project: task.project.id_e,
            sectorId: task?.sector?.id_e,
            startAt: new Date(),
            activityId,
        });
    };

    const handleUpdateRunningEntry = (startAt: string) => {
        if (!runningEntry) return;
        const hour = parseInt(startAt.slice(0, 2), 10);
        const minute = parseInt(startAt.slice(3, 5), 10);
        const now = dayjs();
        const correctedUTCStartAt = now
            .utc()
            .tz("America/Sao_Paulo")
            .hour(hour)
            .minute(minute)
            .toDate();
        updateRunningEntry.mutate({
            entryId: runningEntry.id_e,
            startAt: correctedUTCStartAt,
        });
    };

    return (
        <s.Container
            errors={{
                description: Boolean(errors.description),
                project: Boolean(errors.project),
            }}
            disabled={hourLimitReached}
        >
            {readSubscription.data?.status === "canceled" && isAdminUser && (
                <PlanCanceledBanner
                    limitDate={readSubscription.data.next_billing_at}
                />
            )}
            {readSubscription.data?.status === "failed" && isAdminUser && (
                <ErrorInPaymentBanner subscription={readSubscription.data} />
            )}
            {isOpenModalPlanCards && (
                <ModalPlanCards
                    setOpen={setIsOpenModalPlanCards}
                    freeTrialExpiresDate={planExpiresDate}
                    numberOfUsers={numberOfUsersPlan}
                    planExpired={
                        readSubscription.error?.body?.message?.subscription
                            ?.status === "failed" ||
                        readSubscription.error?.body?.message?.subscription
                            ?.status === "canceled"
                    }
                    trialDays={organization?.Subscription?.trial_days}
                />
            )}
            {getUser?.data?.TimeReports?.[0]?.ReportError[0] && (
                <RefuseReportToggle
                    user={getUser?.data}
                    onClickSendCorrections={sendCorrections}
                    mode="correct"
                />
            )}
            {isTimeLimitReachedModalOpen && (
                <HourLimitReachedModal
                    setOpen={setTimeLimitReachedModalOpen}
                    {...timeLimitReachedModalData}
                />
            )}
            {howYouKnowUsModal && (
                <ModalHowYouKnowUs setOpen={setHowYouKnowUsModal} />
            )}
            {welcomeBannerIsOpen && (
                <WelcomeBanner
                    onClose={() => {
                        const newAccounts = JSON.parse(
                            localStorage.getItem("newAccounts") || "[]"
                        ) as string[];
                        localStorage.setItem(
                            "newAccounts",
                            JSON.stringify(
                                newAccounts.filter(
                                    (account) => account !== user?.email
                                )
                            )
                        );
                        setWelcomeBannerIsOpen(false);
                    }}
                />
            )}
            {isTrialPlanOpen && (
                <DiscoverOurPlans
                    timeToEnd={planExpiresDate}
                    unique={discoverOurPlansUnique}
                    setOpenModalPlans={setIsOpenModalPlanCards}
                />
            )}
            {isOpenWelcomeModal && (
                <ModalWelcomePlan
                    planType={sessionStorage.getItem("planChoice") || ""}
                    setOpen={setIsOpenWelcomeModal}
                />
            )}
            {nineTenthLimitReached && <LimitReachedWarning limits={limits} />}
            {favoriteModalOpen && (
                <ModalFavorites setOpen={setFavoriteModalOpen} />
            )}
            {showDefineDailyGoalModal && (
                <ModalDefineDailyGoal setOpen={setShowDefineDailyGoalModal} />
            )}

            {readEntries.isLoading ? (
                <LoadingGray />
            ) : (
                <>
                    <s.BorderContainer id="month-summary">
                        <div id="container-info">
                            <span>
                                <s.P>Período:</s.P>
                                <s.GrayP
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {getMonthAndYearFromDate(new Date())}
                                </s.GrayP>
                            </span>
                            <HoursInMonth workedHours={workedHours} />
                            {hoursLocked ? (
                                <Tooltip
                                    side="top"
                                    align={"start"}
                                    positiony={10}
                                    content={
                                        <s.LockTooltip>
                                            Horas obrigatórias: você deve
                                            trabalhar o número de horas
                                            previstas, sem ultrapassá-las.
                                        </s.LockTooltip>
                                    }
                                >
                                    <span>
                                        <Icons.iconLockClosedFill
                                            color={theme.gray300}
                                        />
                                        <s.P color={theme.gray200}>
                                            Obrigatório
                                        </s.P>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    side="top"
                                    align={"start"}
                                    positiony={10}
                                    content={
                                        <s.LockTooltip>
                                            Horas mensais flexíveis. Você pode
                                            trabalhar mais que as horas mensais
                                            sugeridas.
                                        </s.LockTooltip>
                                    }
                                >
                                    <span>
                                        <Icons.IconLockOpen
                                            color={theme.gray300}
                                        />
                                        <s.P color={theme.gray200}>
                                            Flexível
                                        </s.P>
                                    </span>
                                </Tooltip>
                            )}
                            <span className="project-hours">
                                <s.P>Horas projetos:</s.P>
                                <Tooltip
                                    side="top"
                                    align={"start"}
                                    positiony={15}
                                    positionx={-20}
                                    content={
                                        <s.LockTooltip>
                                            Clique para ver todos os projetos em
                                            que você participa e as horas
                                            atribuídas a eles.
                                        </s.LockTooltip>
                                    }
                                >
                                    <PopoverHourTrack
                                        align="start"
                                        alignOffset={-144}
                                        trigger={
                                            <Icons.Tag className="project-hours__tag" />
                                        }
                                        getHourControl={getHourControl}
                                    />
                                </Tooltip>
                            </span>
                            <span className="project-hours--favorite">
                                <s.P>Projeto e atividade favoritos:</s.P>
                                <Tooltip
                                    side="top"
                                    align={"start"}
                                    positiony={15}
                                    positionx={-20}
                                    content={
                                        <s.LockTooltip>
                                            Clique para ver o projeto e
                                            atividade favoritos.
                                        </s.LockTooltip>
                                    }
                                >
                                    <div className="project-hours__tag--favorite">
                                        <Icons.Star
                                            width="20px"
                                            color={theme.gray600}
                                            onClick={() =>
                                                setFavoriteModalOpen(true)
                                            }
                                            fill={
                                                favoriteProject ||
                                                favoriteActivity
                                                    ? theme.gray600
                                                    : undefined
                                            }
                                        />
                                    </div>
                                </Tooltip>
                            </span>
                        </div>
                        <span className="value-done">
                            <s.P> Valor realizado: </s.P>
                            <HideInformation
                                isToShowInformation={showMoneyInfo}
                                children={
                                    <s.GrayP>
                                        {formatCurrency(
                                            (
                                                ((user.hourValue ?? 1) *
                                                    workedHours.total) /
                                                60
                                            ).toFixed(2)
                                        )}
                                    </s.GrayP>
                                }
                            />
                            <button
                                onClick={() => setShowMoneyInfo(!showMoneyInfo)}
                            >
                                {showMoneyInfo ? (
                                    <Icons.SenhaFechado color={theme.gray600} />
                                ) : (
                                    <Icons.Eye color={theme.gray600} />
                                )}
                            </button>
                        </span>

                        {hourLimitReached && <RequestMoreHours />}

                        <TimelineTrack
                            limitHours={workedHours.limit}
                            totalTime={workedHours.total}
                        />
                        <s.DailyGoalContainer>
                            <s.DailyGoalTextContainer show={showDailyGoal}>
                                <Icons.PencilOutline
                                    onClick={() => {
                                        setShowDefineDailyGoalModal(true);
                                    }}
                                    color={theme.gray800}
                                />
                                {user.UsersConfigs?.dailyGoal ? (
                                    <s.SmallP>
                                        Meta diária -{" "}
                                        {user.UsersConfigs.dailyGoal}h
                                    </s.SmallP>
                                ) : (
                                    <s.SmallP>Sem meta diária</s.SmallP>
                                )}
                                {!!user.UsersConfigs?.dailyGoal && (
                                    <div
                                        onClick={() => {
                                            setShowDailyGoal(!showDailyGoal);
                                            localStorage.setItem(
                                                "showDailyGoal",
                                                !showDailyGoal
                                                    ? "true"
                                                    : "false"
                                            );
                                        }}
                                    >
                                        <Icons.CollapseArrow />
                                    </div>
                                )}
                            </s.DailyGoalTextContainer>
                            {showDailyGoal &&
                                !!user.UsersConfigs?.dailyGoal && (
                                    <DailyGoalTimeline
                                        limitHours={
                                            user.UsersConfigs.dailyGoal * 60
                                        }
                                        totalTime={calculateWorkedHoursToday()}
                                    />
                                )}
                        </s.DailyGoalContainer>
                    </s.BorderContainer>

                    <s.AssistantButtonWrapper>
                        <s.AssistantButton
                            onClick={() => setVoiceAssistantModal(true)}
                        >
                            Registrar entrada usando a voz{" "}
                            <s.BetaBadge>BETA</s.BetaBadge>
                        </s.AssistantButton>
                    </s.AssistantButtonWrapper>

                    {voiceAssistantModal && (
                        <VoiceAssistant
                            setOpen={setVoiceAssistantModal}
                            createEntry={createEntry}
                        />
                    )}

                    <form onSubmit={onSubmit}>
                        <TutorialsPopover
                            screen="cronômetro"
                            tutorials={[
                                {
                                    url: "T6KkenIaOLw",
                                    label: "Registro de modo manual no cronometro",
                                },
                                {
                                    url: "nF4noABZfDU",
                                    label: "Edição de registro",
                                },
                                {
                                    url: "6EMgOhT2sew",
                                    label: "Criando atividade",
                                },
                                {
                                    label: "Modo manual cronômetro",
                                    url: "WilVqvcElnI",
                                },
                                {
                                    label: "Acessar perfil",
                                    url: "fu8h7xqS68Q",
                                },
                            ]}
                        />
                        <Tooltip
                            align="center"
                            content={
                                <>
                                    {hourLimitReached && (
                                        <s.TimeLimitWarningTooltip
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <header>
                                                <Icons.ExclamationFilled />
                                                Limite de horas
                                            </header>
                                            <p>
                                                Seu cronômetro está inativo,
                                                pois você atingiu 100% do limite
                                                de horas. Se necessário,
                                                solicite mais horas para o seu
                                                gestor.
                                            </p>
                                        </s.TimeLimitWarningTooltip>
                                    )}
                                </>
                            }
                        >
                            <s.BorderContainer
                                id="track-container"
                                disabled={hourLimitReached}
                            >
                                <Controller
                                    control={control}
                                    defaultValue={project?.name}
                                    name="project"
                                    render={({ field: { onChange } }) => (
                                        <SelectProject
                                            onChange={(e) => {
                                                onChange(e);
                                                setSelectedActivity(undefined);
                                            }}
                                            project={project}
                                            errors={errors}
                                            hourControlData={hourControlData}
                                            hourLimitReached={hourLimitReached}
                                            setProject={setProject}
                                            isTimeEntryRunning={isRunning}
                                        />
                                    )}
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={() => {
                                        const disableDescription =
                                            maxLimitReached ||
                                            !project ||
                                            readActivities.isLoading ||
                                            readActivities.isRefetching ||
                                            startEntry.isLoading;
                                        return (
                                            <SelectActivity
                                                type="create"
                                                options={
                                                    projectActivitiesOptions
                                                }
                                                selectedActivity={
                                                    selectedActivity
                                                }
                                                disabled={disableDescription}
                                                onChange={(e) => {
                                                    setSelectedActivity(
                                                        e as TSectionsActivitiesOption
                                                    );
                                                }}
                                                project={project}
                                                createActivity={createActivity}
                                                isTimeEntryRunning={isRunning}
                                            />
                                        );
                                    }}
                                />
                                <div className="track-right-side">
                                    {userConfigs && (
                                        <Tooltip
                                            positiony={10}
                                            content={
                                                <s.ClearDescriptionTooltip>
                                                    Limpar descrição e projeto
                                                    após o registro:{" "}
                                                    <strong>
                                                        {!clearDescription
                                                            ? "INATIVO"
                                                            : "ATIVO"}
                                                    </strong>
                                                </s.ClearDescriptionTooltip>
                                            }
                                        >
                                            <button
                                                type="button"
                                                className="clear-entries-button"
                                                onClick={() => {
                                                    setClearDescription(
                                                        !clearDescription
                                                    );
                                                    updateUserConfigs.mutate({
                                                        userId: getUser.data
                                                            ?.id_e,
                                                        userConfigs: {
                                                            ...userConfigs,
                                                            clearTaskFieldsAfterAdd:
                                                                !clearDescription,
                                                        },
                                                    });
                                                }}
                                            >
                                                <Icons.CleaningBrush
                                                    className="clear-entries"
                                                    height="24px"
                                                    width="24px"
                                                    color={
                                                        !clearDescription
                                                            ? theme.gray500
                                                            : theme.purple500
                                                    }
                                                />
                                            </button>
                                        </Tooltip>
                                    )}
                                    <s.Flex id="timer">
                                        {renderInputFields()}
                                        {editRunningTimerIsOpen &&
                                            isRunning && (
                                                <EditRunningTimerCard
                                                    close={(
                                                        startAt,
                                                        isDirty
                                                    ) => {
                                                        setEditRunningTimerIsOpen(
                                                            false
                                                        );
                                                        if (isDirty) {
                                                            handleUpdateRunningEntry(
                                                                startAt
                                                            );
                                                        }
                                                    }}
                                                    entry={runningEntry}
                                                />
                                            )}
                                    </s.Flex>
                                    <s.Flex id="container-button">
                                        <TrackButtons
                                            isOnManualMode={isOnManualMode}
                                            manualInputsData={manualInputsData}
                                            disabled={
                                                hourLimitReached ||
                                                !selectedActivity?.value ||
                                                createEntry.isLoading
                                            }
                                            loading={
                                                startEntry.isLoading ||
                                                createEntry.isLoading ||
                                                finishEntry.isLoading ||
                                                updateRunningEntry.isLoading
                                            }
                                            loadingTimer={loadingTimer}
                                            isRunning={isRunning}
                                            validateInputs={validateInputs}
                                            selectedDate={selectedDay}
                                        />
                                    </s.Flex>
                                    <s.FlexColumn id="container-modes">
                                        <TrackIcons
                                            disabled={hourLimitReached}
                                            isRunning={isRunning}
                                            handleDeleteCurrentTime={
                                                handleDeleteCurrentTime
                                            }
                                            isOnManualMode={isOnManualMode}
                                            setIsOnManualMode={
                                                setIsOnManualMode
                                            }
                                        />
                                    </s.FlexColumn>
                                </div>
                            </s.BorderContainer>
                        </Tooltip>
                    </form>

                    <s.FlexColumn
                        key={`${timeEntries?.total_entries}${timeEntries?.entries?.length}`}
                        id="cards"
                    >
                        {loading ? (
                            <LoadingGray />
                        ) : (
                            <TrackEntries
                                timeEntries={timeEntries}
                                userProjects={userProjects.filter(
                                    ({ archived }) => archived === false
                                )}
                                updateEntry={updateEntry}
                                handleDeleteEntry={handleDeleteEntry}
                                setTimeEntries={setTimeEntries}
                                continueTask={continueTask}
                            />
                        )}
                    </s.FlexColumn>
                </>
            )}

            {hasNextPage && (
                <Footer
                    isLoading={readEntries.isLoading}
                    onClickShowMore={() => {
                        setReadEntriesLength(readEntriesLength + 10);
                    }}
                />
            )}
        </s.Container>
    );
};
